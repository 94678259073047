import React from "react";
import remark from "remark";
import remarkHTML from "remark-html";
import PropTypes from "prop-types";

const Html = ({ className, content }) => (
  <div
    className={`std ${className}`}
    dangerouslySetInnerHTML={{
      __html: remark().use(remarkHTML).processSync(content).toString(),
    }}
  />
);

Html.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
};

export default Html